












import { userModule } from "@/store/modules/user";
import { defineComponent, onMounted } from "@vue/composition-api";

export default defineComponent({
  name: "Resources",
  setup() {
    onMounted(() => {
      if (userModule.state.user.pro) {
        const article = "https://undervisningilearnlink.notion.site/undervisningilearnlink/PRO-Kursholdere-dbc4487974eb4bb482b873afb719a4f3";
        window.open(article, "_blank")?.focus();
      }
      else {
        const article = "https://www.notion.so/Personlig-L-rer-i-Learnlink-72f7ddb86fb049eaafabf55b8a3e010f";
        window.open(article, "_blank")?.focus();
      }
    });
  },
});
